import clsx from 'clsx';
import Link from 'next/link';
import { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { BsFillPeopleFill } from 'react-icons/bs';
import Imgix from 'react-imgix';
import { Rating } from 'react-simple-star-rating';
import { getImageUrl, getImageUrlWithParams } from '../media/utils/imgix';
import { TripAlgoliaHit } from '../trips/search/TripSearchResult';
import useTripsDiscountRate from '../trips/TripsPage/useTripsDiscountRate';
import PriceFormat from '../utilities/PriceFormat';
import WishlistToggleButton from '../Wishlist/WishlistToggleButton';
import { createImageSizes } from './Gallery';

export default function CardRoom({
  data,
  imgClassName = 'img-fluid w-100',
  aspectRatio = '1350:900',
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  fluid,
  type = 'trip',
}: {
  data: TripAlgoliaHit;
  imgClassName?: string;
  aspectRatio?: string;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  fluid?: boolean;
  type?: 'trip' | 'group';
}) {
  const discountRate = useTripsDiscountRate(
    type === 'trip'
      ? data.trip
      : // We pass the guest price for groups to calculate the discount rate for
        // the group
        {
          minimumPrice: {
            ...data.guestPrice,
            priceBeforeDiscount: data.guestPriceBeforeDiscount,
          },
        },
  );

  const aspectRatioForCSS = aspectRatio
    .split(':')
    .map((n) => n)
    .join('/');

  const priceBeforeDiscount = useMemo(() => {
    if (type === 'group' && data.guestPriceBeforeDiscount?.amountZD) {
      return data.guestPriceBeforeDiscount?.amountZD;
    }

    if (data.trip.minimumPrice?.priceBeforeDiscount && type === 'trip') {
      return data.trip.minimumPrice.priceBeforeDiscount.amountZD;
    }
    return undefined;
  }, [
    data.guestPriceBeforeDiscount?.amountZD,
    data.trip.minimumPrice?.priceBeforeDiscount,
    type,
  ]);

  return (
    <Link
      href={`/trips/${data.trip.urlSlug}`}
      passHref
      className="text-decoration-none"
      draggable={false}
    >
      <Card className="border-0 shadow h-100 relative">
        <div
          className="bg-white rounded-circle"
          style={{
            position: 'absolute',
            bottom: 10,
            right: 10,
            // @ts-expect-error
            '--bs-bg-opacity': 1,
          }}
        >
          <WishlistToggleButton tripId={data.trip.id} />
        </div>

        <div
          className="card-img-top overflow-hidden gradient-overlay"
          style={{
            aspectRatio: aspectRatioForCSS,
          }}
        >
          <Imgix
            src={`${getImageUrl(data.trip.image.file.key)}`}
            imgixParams={{
              auto: 'compress,format,enhance,redeye',
              crop: 'faces,entropy',
              fit: 'crop',
              ar: aspectRatio,
            }}
            sizes={createImageSizes({ xs, sm, md, lg, xl, xxl, fluid })}
            className={clsx(imgClassName)}
            htmlAttributes={{
              src: getImageUrlWithParams(data.trip.image.file.key, {
                auto: 'compress,format',
                blur: '200',
                px: '32px',
                fit: 'crop',
                ar: aspectRatio,
                crop: 'faces,entropy',
                w: 1000,
              }),
              alt: data.trip.title,
            }}
          />

          {(data.trip.overlayText || discountRate) && (
            <div
              className="bg-secondary text-white text-xs px-2 py-1 rounded-1"
              style={{ position: 'absolute', top: 0, right: 0 }}
            >
              {discountRate
                ? `ON SALE: Save ${discountRate}%`
                : data.trip.overlayText}
            </div>
          )}
        </div>

        <Card.Body className="d-flex align-items-center">
          <div className="w-100 d-block text-start">
            <Card.Title
              as="h6"
              className="w-100 flex-wrap"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {data.trip.title}
            </Card.Title>
            <Card.Subtitle className="d-flex flex-wrap flex-row mb-3" as="div">
              <p className="me-auto mb-0 text-muted text-sm">{data.country}</p>
              {data.trip.reviewsSummary?.reviewCount > 0 && (
                <p className="mb-0 card-stars text-xs text-end">
                  <Rating
                    initialValue={
                      data.trip.reviewsSummary?.fiveStarRatingAverage
                    }
                    readonly
                    allowFraction
                    size={18}
                  />
                  <span
                    className="text-muted d-inline-block ms-1"
                    style={{ position: 'relative', top: 1 }}
                  >
                    {data.trip.reviewsSummary.reviewCount}
                  </span>
                </p>
              )}
            </Card.Subtitle>
            <Card.Text className="text-muted">
              {priceBeforeDiscount && (
                <div>
                  <span className="text-decoration-line-through me-2">
                    <PriceFormat
                      amount={priceBeforeDiscount}
                      currencyIso={
                        data.trip.minimumPrice.priceBeforeDiscount.currency
                      }
                      convert
                      roundDollar
                      short
                    />
                  </span>
                </div>
              )}
              <div
                className="d-flex flex-column mx-auto"
                style={{
                  maxWidth: '100%',
                  gap: '0.5rem',
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: 'inline-flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <span
                    className={`h4 mb-0 ${priceBeforeDiscount ? 'text-secondary' : 'text-primary'}`}
                  >
                    <PriceFormat
                      amount={
                        type === 'trip'
                          ? (data.trip?.minimumPrice?.amountZD ??
                            data.guestPrice.amountZD)
                          : // For groups, we use the guest price, to show actual price for the departing group
                            data.guestPrice.amountZD
                      }
                      currencyIso={
                        data.trip?.minimumPrice?.currency ??
                        data.guestPrice.currency
                      }
                      convert
                      roundDollar
                      short
                    />
                    &nbsp;
                  </span>
                  <div style={{}}>
                    <span
                      style={{
                        verticalAlign: 'baseline',
                      }}
                      className="text-muted text-sm"
                    >
                      per person
                    </span>
                  </div>
                </div>
                {type === 'group' && (
                  <div
                    className="d-flex text-muted pe-4 align-items-center fw-bold"
                    style={{
                      gap: '0.2rem',
                    }}
                  >
                    <BsFillPeopleFill size={14} />
                    <div className="text-sm">
                      {data.remainingSpaces} spots left
                    </div>
                  </div>
                )}
              </div>
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </Link>
  );
}
